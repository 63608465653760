import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { links } from "../../lib/links";

const Footer = () => {
  return (
    <Wrapper>
      <FooterLinksWrapper>
        {footerLinks.map((item) => (
          <Link to={item.link} key={item.text}>
            <FooterLink>{item.text}</FooterLink>
          </Link>
        ))}
      </FooterLinksWrapper>
      <LogoText>Anna</LogoText>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  marginTop: "90px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  rowGap: "20px",

  [theme.breakpoints.down("sm")]: {
    marginTop: "30px",
  },
}));

const FooterLinksWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "30px",

  [theme.breakpoints.down("sm")]: {
    columnGap: "20px",
  },
}));

const FooterLink = styled("div")({
  fontSize: "14px",
  fontWeight: 100,
});

const LogoText = styled("div")({
  fontSize: "60px",
  color: "#00000",
  fontWeight: 700,
  fontFamily: "var(--font-cinzel)",
});

interface FooterLink {
  text: string;
  link: string;
}

const footerLinks: FooterLink[] = [
  {
    text: "Про нас",
    link: links.aboutUs(),
  },
  {
    text: "Питання і відповіді",
    link: links.faq(),
  },
  {
    text: "Контакти",
    link: links.contactUs(),
  },
];

export default Footer;
