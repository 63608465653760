import React, { useMemo, useState } from "react";
import { styled } from "@mui/material";
import { StrapiObject } from "../../types/strapi";
import { DressApiData, DressLength } from "../../types/dressApi";
import Carousel from "./components/Carousel";
import DressDetails from "./components/DressDetails";

interface Props {
  data: StrapiObject<DressApiData>;
}

const DressInfo = ({ data }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const [currentColor, setCurrentColor] = useState(
    data.attributes.image[0].color
  );

  const [currentLength, setCurrentLength] = useState(DressLength.mini);

  const handleCurrentLengthChange = (length: DressLength) => {
    if (showAll) return;
    setCurrentLength(length);
  };

  const handleChangeActiveColor = (color: string) => {
    if (showAll) return;
    setCurrentColor(color);
  };

  const handleShowAllChange = (status: boolean) => {
    setShowAll(status);
  };

  const imageUrls = useMemo(() => {
    return data.attributes.image
      .filter(
        (item) =>
          (item.color === currentColor && item.length === currentLength) ||
          showAll
      )
      .map((item) => item.photos.data.map((photo) => photo.attributes.url))
      .flat();
  }, [data, currentColor, currentLength, showAll]);

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Carousel images={imageUrls} />
        <DressDetails
          data={data.attributes}
          filters={{
            currentColor,
            currentLength,
            handleChangeActiveColor,
            handleCurrentLengthChange,
            handleShowAllChange,
            showAll,
          }}
        />
      </InnerWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  paddingTop: "180px",

  [theme.breakpoints.down("sm")]: {
    paddingTop: "0",
  },
}));

const InnerWrapper = styled("div")(({ theme }) => ({
  padding: "40px",
  display: "flex",
  justifyContent: "center",
  columnGap: "120px",
  maxWidth: "80%",
  width: "1000px",
  margin: "0 auto",

  "& > div": {
    width: "calc(50% - 60px)",
  },

  [theme.breakpoints.down("md")]: {
    columnGap: "30px",
    padding: "40px 10px",
    maxWidth: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "40px",
    maxWidth: "100%",
    width: "auto",
    padding: "20px",

    "& > div": {
      width: "100%",
    },
  },
}));

export default DressInfo;
