import React from "react";
import { styled } from "@mui/material";
import { links } from "../../../lib/links";
import { Link } from "react-router-dom";

interface Props {
  id: number;
  name: string;
  photoUrl: string;
}

const DressCard = ({ id, name, photoUrl }: Props) => {
  return (
    <Wrapper to={links.dress(id)}>
      <ImageWrapper>
        <img alt={name} src={photoUrl} style={{ objectFit: "cover" }} />
      </ImageWrapper>
      <Title>{name}</Title>
    </Wrapper>
  );
};

const Wrapper = styled(Link)(({ theme }) => ({
  width: "calc(32.9% - 10px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "20px",

  [theme.breakpoints.down("md")]: {
    width: "calc(50% - 10px)",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "660px",
  position: "relative",
  overflow: "hidden",

  "& > img": {
    transition: "transform 0.2s",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "&:hover > img": {
    transform: "scale(1.05)",
  },

  [theme.breakpoints.down("lg")]: {
    height: "500px",
  },

  [theme.breakpoints.down("md")]: {
    height: "300px",
  },

  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
}));

const Title = styled("div")({
  fontSize: "18px",
});

export default DressCard;
