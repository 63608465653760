import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Video from "./Video";
import Catalogs from "./Catalogs";
import Footer from "../../components/Footer/Footer";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const HomePage = () => {
  const [data, setData] = useState<{ videoUrl: any; categories: any }>();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const endVideo = () => {
    setIsVideoEnded(true);
  };

  const endLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const getHomepageData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/home-page?populate[0]=Category.photo&populate[1]=video`,
        {
          cache: "no-store",
        }
      );

      if (!res.ok) {
        throw new Error("Failed to fetch home page data");
      }

      const data = await res.json();

      const videoUrl =
        process.env.REACT_APP_API_URL +
        data.data.attributes.video.data.attributes.url;

      const categories = data.data.attributes.Category.map((item: any) => ({
        title: item.title,
        categoryName: item.category,
        photoUrl:
          process.env.REACT_APP_API_URL + item.photo.data.attributes.url,
      }));
      setData({ videoUrl, categories });
      endLoading();
    };

    getHomepageData();
  }, []);

  return (
    <div>
      {data && !isLoading && isVideoEnded ? (
        <>
          <Navbar reverseColors={false} />
          <Video videoUrl={data.videoUrl} />
          <Catalogs categories={data.categories} />
          <Footer />
        </>
      ) : (
        <LoadingScreen endLoading={endLoading} endVideo={endVideo} />
      )}
    </div>
  );
};

export default HomePage;
