import React, { useState } from "react";
import { useEffect } from "react";
import { IconButton, styled } from "@mui/material";
import Drawer from "../Drawer/Drawer";
import { BurgerIcon } from "../icons";
import { Link } from "react-router-dom";
import { links } from "../../lib/links";

interface Props {
  reverseColors: boolean;
}

const Navbar = ({ reverseColors }: Props) => {
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
  const [display, setDisplay] = useState<boolean>(true);
  const [inverseColors, setInverseColors] = useState(false);

  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    const updateScrollbar = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos || currentScrollPos === 0) {
        setDisplay(true);
        if (!currentScrollPos) {
          setInverseColors(false);
        } else {
          setInverseColors(true);
        }
      } else {
        setDisplay(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", updateScrollbar);

    return () => window.removeEventListener("scroll", updateScrollbar);
  }, [prevScrollPos]);

  const updateDrawerOpen = (status: boolean) => setDrawerOpened(status);

  useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <OuterWrapper>
      <DesktopOnly>
        <InnterWrapper
          display={display}
          inverseColors={inverseColors}
          reverseColors={reverseColors}
        >
          <Link to={links.home()}>
            <LogoText reverseColors={reverseColors}>Anna</LogoText>
          </Link>
          <TabsWrapper>
            {navbarLinks.map((item) => (
              <Link to={item.link} key={item.text}>
                <Tab reverseColors={reverseColors}>{item.text}</Tab>
              </Link>
            ))}
          </TabsWrapper>
        </InnterWrapper>
      </DesktopOnly>
      <MobileOnly>
        <MobileNavbar>
          <Link to={links.home()}>
            <MobileLogoText>Anna</MobileLogoText>
          </Link>
          <StyledIconButton onClick={() => updateDrawerOpen(true)}>
            <BurgerIcon />
          </StyledIconButton>
        </MobileNavbar>
      </MobileOnly>
      <Drawer
        navbarLinks={navbarLinks}
        isOpened={drawerOpened}
        updateOpen={updateDrawerOpen}
      />
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  zIndex: 2,

  [theme.breakpoints.down("sm")]: {
    position: "relative",
  },
}));

const InnterWrapper = styled("div")<{
  display: boolean;
  inverseColors: boolean;
  reverseColors: boolean;
}>(({ display, inverseColors, reverseColors, theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  position: "relative",
  transform: display ? "translateY(0)" : "translateY(-100%)",
  background: inverseColors
    ? reverseColors
      ? "#ffffff"
      : "#000000"
    : "transparent",
  transition: "0.2s",

  [theme.breakpoints.down("sm")]: {
    transform: "translateY(0)",
    background: "#transparent",
  },
}));

const TabsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: "15px",
});

const Tab = styled("div")<{ reverseColors: boolean }>(({ reverseColors }) => ({
  color: reverseColors ? "#000000" : "#ffffff",
  position: "relative",
  cursor: "pointer",
  padding: "5px 0",
  display: "inline-block",
  fontSize: "20px",
  fontWeight: 100,

  "&::after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    background: reverseColors ? "#000000" : "#ffffff",
    left: "0px",
    bottom: "0px",
    transformOrigin: "bottom right",
    transform: "scaleX(0)",
    transition: "transform 0.25s ease-out",
  },

  "&:hover::after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

const LogoText = styled("div")<{ reverseColors: boolean }>(
  ({ reverseColors }) => ({
    fontSize: "90px",
    color: reverseColors ? "#000000" : "#ffffff",
    fontWeight: 700,
    fontFamily: "var(--font-cinzel)",
  })
);

const MobileLogoText = styled("div")({
  fontSize: "30px",
  color: "#000000",
  fontWeight: 700,
  fontFamily: "var(--font-cinzel)",
});

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MobileOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const MobileNavbar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
});

const StyledIconButton = styled(IconButton)({
  width: "40px",
  height: "40px",
  overflow: "hidden",
});

export interface NavbarLink {
  text: string;
  link: string;
}

const navbarLinks: NavbarLink[] = [
  {
    text: "Весільні",
    link: links.bridalDresses(""),
  },
  {
    text: "Вечірні",
    link: links.eveningDresses(""),
  },
  {
    text: "Дитячі",
    link: links.childrenDresses(""),
  },
  {
    text: "Про нас",
    link: links.aboutUs(),
  },
  {
    text: "Контакти",
    link: links.contactUs(),
  },
  {
    text: "Питання",
    link: links.faq(),
  },
];

export default Navbar;
