import React, { useEffect, useState } from "react";
import { StrapiObject } from "../../types/strapi";
import { DressApiData } from "../../types/dressApi";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DressesCatalog from "./DressesCatalog";

const CatalogPage = () => {
  const { slug } = useParams();

  const [dressesData, setDressesData] = useState<
    {
      id: number;
      name: string;
      photoUrl: string;
    }[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!slug) return;
    const getCatalogData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/api/dresses?populate[0]=image.photos&filters[category][$eq]=${slug}`,
          {
            cache: "no-store",
          }
        );

        if (!res.ok) {
          throw new Error("Failed to fetch home page data");
        }

        const data: { data: StrapiObject<DressApiData>[] } = await res.json();

        const dressesData = data.data
          .map((item) => {
            const photoUrl =
              process.env.REACT_APP_API_URL +
              item.attributes.image[0].photos.data[0].attributes.url;
            return {
              id: item.id,
              name: item.attributes.name,
              photoUrl,
            };
          })
          .filter((item: any) => !!item);
        setDressesData(dressesData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getCatalogData();
  }, [slug]);

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Navbar reverseColors={true} />
      <DressesCatalog dresses={dressesData} isLoading={isLoading} />
      <Footer />
    </div>
  );
};

export default CatalogPage;
