import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  categoryName: string;
  photoUrl: string;
}

const CatalogCard = ({ categoryName, photoUrl, title }: Props) => {
  return (
    <Wrapper to={`/catalog/${categoryName}`}>
      <ImageWrapper>
        <img alt={categoryName} src={photoUrl} />
      </ImageWrapper>
      <Title>{title}</Title>
      <ViewAll>Відкрити</ViewAll>
    </Wrapper>
  );
};

const Wrapper = styled(Link)(({ theme }) => ({
  width: "calc(50% - 10px)",
  display: "flex",
  flexDirection: "column",
  rowGap: "30px",
  alignItems: "flex-start",

  [theme.breakpoints.down("md")]: {
    rowGap: "20px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "560px",
  position: "relative",
  overflow: "hidden",

  "& > img": {
    transition: "transform 0.2s",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "&:hover > img": {
    transform: "scale(1.05)",
  },

  [theme.breakpoints.down("md")]: {
    height: "420px",
  },
}));

const Title = styled("div")({
  color: "#000000",
  position: "relative",
  cursor: "pointer",
  padding: "5px 0",
  display: "inline-block",
  fontSize: "25px",

  "&::after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    background: "#000000",
    left: "0px",
    bottom: "0px",
    transformOrigin: "bottom left",
    transform: "scaleX(1)",
    transition: "transform 0.25s ease-out",
  },

  "&:hover::after": {
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
  },
});

const ViewAll = styled("div")({
  color: "#000000",
  position: "relative",
  cursor: "pointer",
  padding: "5px 0",
  display: "inline-block",
  fontSize: "15px",

  "&::after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    background: "#000000",
    left: "0px",
    bottom: "0px",
  },
});

export default CatalogCard;
