import React from "react";
import { styled } from "@mui/material";
import CatalogCard from "./components/CatalogCard";

interface Props {
  categories: {
    title: string;
    categoryName: string;
    photoUrl: string;
  }[];
}

const Catalogs = ({ categories }: Props) => {
  return (
    <OuterWrapper>
      <Wrapper>
        {categories.map((item) => (
          <CatalogCard {...item} key={item.title} />
        ))}
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  padding: "40px",
  margin: "30px 0 0",

  [theme.breakpoints.down("sm")]: {
    padding: "10px 20px",
    margin: "0",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  columnGap: "20px",
  flexWrap: "wrap",
  rowGap: "20px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "60px",
  },
}));

export default Catalogs;
