export const links = {
  home: () => "/",
  bridalDresses: (param: string) => `/catalog/bridal-dresses/${param}`,
  eveningDresses: (param: string) => `/catalog/evening-dresses/${param}`,
  childrenDresses: (param: string) => `/catalog/children-dresses/${param}`,
  contactUs: () => "/contact-us",
  faq: () => "/faq",
  dress: (id: number) => `/dress/${id}`,
  aboutUs: () => "/about-us",
};
