import { styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

interface Props {
  images: string[];
}

const Carousel = ({ images }: Props) => {
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const sliderRef1 = useRef<Slider>(null);

  const sliderRef2 = useRef<Slider>(null);

  useEffect(() => {
    if (!sliderRef1.current || !sliderRef2.current) return;

    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, [images]);

  return (
    <OuterWrapper>
      <FirstSliderWrapper>
        <Slider
          asNavFor={nav2}
          ref={sliderRef1}
          infinite={images.length > 1}
          arrows={images.length > 1}
        >
          {images.map((item) => (
            <StyledMainImage src={item} key={item} />
          ))}
        </Slider>
      </FirstSliderWrapper>
      <SecondSliderWrapper>
        <Slider
          asNavFor={nav1}
          ref={sliderRef2}
          slidesToShow={images.length > 3 ? 3 : images.length}
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={images.length > 1}
          arrows={false}
        >
          {images.map((item) => (
            <StyledSecondaryImage src={item} key={item} />
          ))}
        </Slider>
      </SecondSliderWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")({
  width: "30%",
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  alignItems: "center",

  "& .slick-track": {
    display: "flex",
  },

  "& .slick-slide": {
    height: "auto",
    display: "flex !important",
    justifyContent: "center",
  },

  "& .slick-slider > button:before": {
    color: "#a5a5a5",
  },
});

const FirstSliderWrapper = styled("div")({
  width: "90%",

  "& .slick-slide > div": {
    height: "100%",
  },
});

const SecondSliderWrapper = styled("div")({
  width: "70%",

  "& img": {
    filter: "grayscale(1)",
  },

  "& .slick-current": {
    "& img": {
      filter: "grayscale(0)",
    },
  },

  "& .slick-track": {
    height: "140px",
  },
});

const StyledMainImage = styled("img")({
  objectFit: "cover",
  maxWidth: "100%",
  height: "520px",
});

const StyledSecondaryImage = styled("img")({
  objectFit: "cover",
  maxWidth: "90px",
  minHeight: "100%",
  margin: "0 auto",
});

export default Carousel;
