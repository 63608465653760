import React, { FC } from "react";
import { styled } from "@mui/material";

interface Props {
  videoUrl: string;
}

const Video: FC<Props> = ({ videoUrl }) => {
  return (
    <DesktopOnly>
      <Wrapper>
        <StyledVideo src={videoUrl} autoPlay loop muted />
      </Wrapper>
    </DesktopOnly>
  );
};

const Wrapper = styled("div")({});

const StyledVideo = styled("video")({
  width: "100%",
});

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default Video;
