import React, { useEffect, useState } from "react";
import { StrapiObject } from "../../types/strapi";
import { DressApiData } from "../../types/dressApi";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DressInfo from "./DressInfo";
import { CircularProgress, styled } from "@mui/material";

const DressPage = () => {
  const { id } = useParams();

  const [data, setData] = useState<StrapiObject<DressApiData>>();
  const [isLoading, setIsLoading] = useState(true);

  const endLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    const getDressData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/api/dresses/${id}?populate[0]=image.photos`,
          {
            cache: "no-store",
          }
        );

        if (!res.ok) {
          throw new Error("Failed to fetch home page data");
        }

        const jsonResult = await res.json();

        const data: StrapiObject<DressApiData> = jsonResult.data;

        data.attributes.image.forEach((item) => {
          item.photos.data.forEach((photo) => {
            const defaultUrl = photo.attributes.url;
            photo.attributes.url = process.env.REACT_APP_API_URL + defaultUrl;
          });
        });
        setData(data);
        endLoading();
      } catch (error) {
        endLoading();
      }
    };
    getDressData();
  }, [id]);

  return (
    <div>
      {data && !isLoading ? (
        <>
          <Navbar reverseColors={true} />
          <DressInfo data={data} />
          <Footer />
        </>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </div>
  );
};

const LoaderWrapper = styled("div")({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  rowGap: "20px",
});

export default DressPage;
