import { Button, styled } from "@mui/material";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const NoSuchPage = () => {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Navbar reverseColors />
      <OuterWrapper>
        <EmptyText>
          Дана сторінка поки що в процесі розробки. Просимо вибачення за
          незручності {";)"}
        </EmptyText>
      </OuterWrapper>
      <Footer />
    </div>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  paddingTop: "180px",

  [theme.breakpoints.down("sm")]: {
    paddingTop: "0",
  },
}));

const BackButtonWrapper = styled("div")({
  position: "absolute",
  left: "20px",
  top: "200px",
});

const StyledButton = styled(Button)({
  color: "#000",
});

const EmptyText = styled("div")({
  textAlign: "center",
  width: "100%",
  fontWeight: 100,
  fontSize: "20px",
  padding: "0 10px",
  lineHeight: "25px",
});

export default NoSuchPage;
