import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NoSuchPage from "./modules/NoSuchPageYet/NoSuchPage";
import CatalogPage from "./modules/Catalog/CatalogPage";
import DressPage from "./modules/Dress/DressPage";
import HomePage from "./modules/Home/HomePage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { links } from "./lib/links";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/about-us" element={<NoSuchPage />} />
        <Route path="/catalog/:slug" element={<CatalogPage />} />
        <Route path="/contact-us" element={<NoSuchPage />} />
        <Route path="/dress/:id" element={<DressPage />} />
        <Route path="/faq" element={<NoSuchPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
};

export interface NavbarLink {
  text: string;
  link: string;
}

export const navbarLinks: NavbarLink[] = [
  {
    text: "Весільні",
    link: links.bridalDresses(""),
  },
  {
    text: "Вечірні",
    link: links.eveningDresses(""),
  },
  {
    text: "Дитячі",
    link: links.childrenDresses(""),
  },
  {
    text: "Про нас",
    link: links.aboutUs(),
  },
  {
    text: "Контакти",
    link: links.contactUs(),
  },
  {
    text: "Питання",
    link: links.faq(),
  },
];

export default App;
