import { StrapiImage, StrapiObject } from "./strapi";

export enum DressApiCategory {
  bridal = "bridal-dresses",
  evening = "evening-dresses",
  children = "children-dresses",
}

export interface DressApiData {
  name: string;
  description: string;
  category: DressApiCategory;
  image: DressImage[];
}

export enum DressLength {
  mini = "mini",
  maxi = "maxi",
  midi = "midi",
}

export interface DressImage {
  id: number;
  color: string;
  length: DressLength;
  size: string;
  colorName: string;
  photos: {
    data: StrapiObject<StrapiImage>[];
  };
}
