import React from "react";
import {
  Button,
  IconButton,
  List,
  ListItem,
  Drawer as MuiDrawer,
  styled,
} from "@mui/material";
import { CrossIcon } from "../icons";
import { Link, useNavigate } from "react-router-dom";
import { links } from "../../lib/links";
import { NavbarLink } from "../../App";

interface Props {
  navbarLinks: NavbarLink[];
  isOpened: boolean;
  updateOpen: (status: boolean) => void;
}

const Drawer = ({ navbarLinks, isOpened, updateOpen }: Props) => {
  const navigate = useNavigate();

  const handleClose = () => updateOpen(false);

  const handleItemClick = (link: string) => {
    handleClose();
    navigate(link);
  };

  return (
    <StyledDrawer anchor={"right"} open={isOpened} onClose={handleClose}>
      <LogoTextWrapper>
        <Link to={links.home()}>
          <LogoText>Anna</LogoText>
        </Link>
      </LogoTextWrapper>
      <CloseIconWrapper>
        <StyledIconButton onClick={handleClose}>
          <CrossIcon />
        </StyledIconButton>
      </CloseIconWrapper>
      <StyledList>
        {navbarLinks.map((item) => (
          <StyledListItem key={item.text}>
            <StyledButton onClick={() => handleItemClick(item.link)}>
              {item.text}
            </StyledButton>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(MuiDrawer)({
  "& .MuiPaper-root": {
    width: "100%",
    position: "relative",
  },
});

const CloseIconWrapper = styled("div")({
  position: "absolute",
  right: "20px",
  top: "20px",
  zIndex: 10,
});

const StyledIconButton = styled(IconButton)({
  width: "40px",
  height: "40px",
});

const StyledList = styled(List)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "center",

  "& > a": {
    width: "100%",
  },
});

const StyledButton = styled(Button)({
  color: "#000",
  background: "transparent",
  width: "100%",
});

const LogoTextWrapper = styled("div")({
  position: "absolute",
  left: "20px",
  top: "20px",
  zIndex: 10,
});

const LogoText = styled("div")(() => ({
  fontSize: "30px",
  color: "#000000",
  fontWeight: 700,
  fontFamily: "var(--font-cinzel)",
}));

export default Drawer;
