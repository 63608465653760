import React from "react";
import { CircularProgress, styled } from "@mui/material";
import DressCard from "./components/DressCard";

interface Props {
  dresses: {
    id: number;
    name: string;
    photoUrl: string;
  }[];
  isLoading: boolean;
}

const DressesCatalog = ({ dresses, isLoading }: Props) => {
  return (
    <OuterWrapper>
      <InnerWrapper>
        {dresses.length ? (
          dresses.map((item) => <DressCard {...item} key={item.id} />)
        ) : isLoading ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <EmptyText>No dresses for now</EmptyText>
        )}
      </InnerWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  paddingTop: "180px",
  flex: 1,

  [theme.breakpoints.down("sm")]: {
    paddingTop: "0",
  },
}));

const LoaderWrapper = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  rowGap: "20px",
  width: "100%",
}));

const InnerWrapper = styled("div")(({ theme }) => ({
  padding: "40px",
  display: "flex",
  flexWrap: "wrap",
  columnGap: "20px",
  rowGap: "20px",

  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const EmptyText = styled("div")({
  textAlign: "center",
  width: "100%",
  paddingTop: "30px",
  fontWeight: 900,
  fontSize: "20px",
});

export default DressesCatalog;
