import React, { useEffect, useState } from "react";
import { Chip, Switch, styled } from "@mui/material";
import { DressApiData, DressLength } from "../../../types/dressApi";

interface Props {
  data: DressApiData;
  filters: {
    showAll: boolean;
    currentColor: string;
    currentLength: DressLength;
    handleCurrentLengthChange: (length: DressLength) => void;
    handleChangeActiveColor: (color: string) => void;
    handleShowAllChange: (status: boolean) => void;
  };
}

const DressDetails = ({
  data: { description, image, name },
  filters: {
    currentColor,
    currentLength,
    handleChangeActiveColor,
    handleCurrentLengthChange,
    handleShowAllChange,
    showAll,
  },
}: Props) => {
  const getAvailableLength = () => {
    return [
      ...new Set(
        image
          .filter((item) => item.color === currentColor)
          .map((item) => item.length)
      ),
    ];
  };

  const getAvailableSizes = () => {
    return [
      ...new Set(
        image
          .filter(
            (item) =>
              item.color === currentColor && item.length === currentLength
          )
          .map((item) => item.size)
      ),
    ];
  };

  const getColorName = (color: string) => {
    return image.find((item) => item.color === color)?.colorName || "";
  };

  const getUniqueColors = () => [...new Set(image.map((item) => item.color))];

  const allLength = [...new Set(image.map((item) => item.length))];

  const allSizes = [...new Set(image.map((item) => item.size))];

  useEffect(() => {
    const availableLength = getAvailableLength();
    handleCurrentLengthChange(availableLength[0]);
  }, [currentColor]);

  return (
    <OuterWrapper>
      <Section>
        <Label>Назва</Label>
        <Name>{name}</Name>
      </Section>
      <Section>
        <Label>Опис</Label>
        <Description>{description}</Description>
      </Section>
      <Section>
        <Label>Показати всі фото</Label>
        <Switch
          onChange={(e) => handleShowAllChange(e.target.checked)}
          value={showAll}
        />
      </Section>
      <Section>
        <Label>Доступні кольори</Label>
        <MultipleItemsWrapperRow>
          {getUniqueColors().map((color) => (
            <Color
              key={color}
              color={color}
              active={color === currentColor || showAll}
              onClick={() => handleChangeActiveColor(color)}
            />
          ))}
        </MultipleItemsWrapperRow>
      </Section>
      <Section>
        <Label>Назва кольору</Label>
        <ColorName>
          {showAll ? (
            <MultipleItemsWrapperColumn>
              {getUniqueColors()
                .map(getColorName)
                .map((item) => (
                  <div key={item}>{item}</div>
                ))}
            </MultipleItemsWrapperColumn>
          ) : (
            getColorName(currentColor)
          )}
        </ColorName>
      </Section>
      <Section>
        <Label>Доступні довжини сукні</Label>
        <MultipleItemsWrapperRow>
          {(showAll ? allLength : getAvailableLength()).map((length) => (
            <StyledChip
              key={length}
              label={length}
              color={
                length === currentLength || showAll ? "primary" : "default"
              }
              onClick={() => handleCurrentLengthChange(length)}
            />
          ))}
        </MultipleItemsWrapperRow>
      </Section>
      <Section>
        <Label>Доступні розміри</Label>
        <MultipleItemsWrapperRow>
          {(showAll ? allSizes : getAvailableSizes()).map((size) => (
            <Size key={size}>{size}</Size>
          ))}
        </MultipleItemsWrapperRow>
      </Section>
    </OuterWrapper>
  );
};

const OuterWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const Section = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  width: "100%",
});

const Label = styled("div")({
  fontWeight: "bold",
  fontSize: "20px",
});

const Name = styled("div")({});

const Description = styled("div")({});

const ColorName = styled("div")({});

const Color = styled("div")<{ color: string; active: boolean }>(
  ({ color, active }) => ({
    width: "30px",
    height: "30px",
    border: active ? "1px solid #000" : "1px solid #fff",
    background: color,
    cursor: "pointer",
  })
);

const MultipleItemsWrapperRow = styled("div")({
  display: "flex",
  columnGap: "5px",
  flexWrap: "wrap",
});

const MultipleItemsWrapperColumn = styled("div")({
  display: "flex",
  rowGap: "5px",
  flexDirection: "column",
});

const StyledChip = styled(Chip)({
  cursor: "pointer",
});

const Size = styled("div")({
  background: "#efefef",
  border: "1px solid #000",
  padding: "5px",
});

export default DressDetails;
