import { CircularProgress, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  endLoading: () => void;
  endVideo: () => void;
}

const LoadingScreen = ({ endLoading, endVideo }: Props) => {
  const [isDisplayedOnce, setIsDisplayedOnce] = useState(false);

  const videoRef = useRef<any>(undefined);
  useEffect(() => {
    if (videoRef?.current?.defaultMuted) videoRef.current.defaultMuted = true;
  });

  const handleEndVideoLoading = () => {
    setIsDisplayedOnce(true);
    endLoading();
    endVideo();
    sessionStorage.setItem("first", "false");
  };

  useEffect(() => {
    const isNotFirstOpen = sessionStorage.getItem("first");
    if (isNotFirstOpen) {
      setIsDisplayedOnce(true);
      endVideo();
    } else {
      setTimeout(() => {
        handleEndVideoLoading();
      }, 5000);
    }
  }, []);

  return (
    <Wrapper>
      {isDisplayedOnce ? (
        <CircularProgress />
      ) : (
        <video
          onEnded={handleEndVideoLoading}
          autoPlay
          muted
          ref={videoRef}
          playsInline
        >
          <source src="LogoAnimation.mp4" type="video/mp4" />
        </video>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  rowGap: "20px",

  "& video": {
    maxWidth: "50%",
  },

  [theme.breakpoints.down("md")]: {
    "& video": {
      maxWidth: "100%",
    },
  },
}));

export default LoadingScreen;
